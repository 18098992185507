import * as amplitude from '@amplitude/analytics-browser';
import { plugin as engagementPlugin } from '@amplitude/engagement-browser';

// Guides and Surveys SDK
amplitude.add(engagementPlugin());

amplitude.init('3d7e0842e746c32dedca91b2c32bf578', { "autocapture": true });

amplitude.setUserId(document.querySelector("meta[name=user_id]")?.content)
amplitude.setGroup('account_id', document.querySelector("meta[name=account_id]")?.content)
